<template>
	<div class="introduction">
	   <img class="topicon"  src="@/assets/images/introduction/a01.jpg">
	    <ul class="intr_nav">
	    	<li @click="getIndex(0)"  :class="{ li_active: 0 == activeIndex }">
	    		<img src="@/assets/images/introduction/b01.png">
	    		<span :class="{ tit_active: 0 == activeIndex }" >企业文化</span>
	    	</li>
	    	<li @click="getIndex(1)" :class="{ li_active: 1 == activeIndex }">
	    		<img src="@/assets/images/introduction/b02.png">
	    		<span :class="{ tit_active: 1 == activeIndex }">发展历程</span>
	    	</li>
	    	<li @click="getIndex(2)" :class="{ li_active: 2 == activeIndex }">
	    		<img src="@/assets/images/introduction/b03.png">
	    		<span :class="{ tit_active: 2 == activeIndex }">专业认证</span>
	    	</li>
	    	<li @click="getIndex(3)" :class="{ li_active: 3 == activeIndex }">
	    		<img src="@/assets/images/introduction/b04.png">
	    		<span :class="{ tit_active: 3 == activeIndex }">万方生态</span>
	    	</li>
	    	<li @click="getIndex(4)" :class="{ li_active: 4 == activeIndex }">
	    		<img src="@/assets/images/introduction/b05.png">
	    		<span :class="{ tit_active: 4 == activeIndex }">主营业务</span>
	    	</li>
	    	<li @click="getIndex(5)" :class="{ li_active: 5 == activeIndex }">
	    		<img src="@/assets/images/introduction/b06.png">
	    		<span :class="{ tit_active: 5 == activeIndex }">主要客户</span>
	    	</li>
	    	<li @click="getIndex(6)" :class="{ li_active: 6 == activeIndex }">
	    		<img src="@/assets/images/introduction/b07.png">
	    		<span :class="{ tit_active: 6 == activeIndex }">联系方式</span>
	    	</li>
	    </ul>
	    <ul>
	    	<li v-if="activeIndex==0">
	    	  <img src="@/assets/images/introduction/a02.jpg">
	    	</li>
	    	<li v-if="activeIndex==1">
	    	  <img src="@/assets/images/introduction/a03.jpg">
	    	</li>
	    	<li v-if="activeIndex==2">
	    	  <img src="@/assets/images/introduction/a04.jpg">
	    	</li>
	    	<li v-if="activeIndex==3">
	    	  <img src="@/assets/images/introduction/a05.jpg">
	    	</li>
	    	<li v-if="activeIndex==4">
	    	  <img src="@/assets/images/introduction/a06.jpg">
	    	</li>
	    	<li v-if="activeIndex==5">
	    	  <img src="@/assets/images/introduction/a07.jpg">
	    	</li>
	    	<li v-if="activeIndex==6">
	    	  <img src="@/assets/images/introduction/a08.jpg">
	    	</li>
	    	
	    </ul>

	</div>
</template>
<script type="text/javascript">
export default{
  data(){
  	return{
  		activeIndex:0
  	}
  },
  mounted(){
  	this.activeIndex=this.$route.query.id
  	this.getIndex(this.activeIndex)
  },
  methods:{
  	getIndex(val){
      this.activeIndex=val;
  	}
  },
  watch:{
     $route(){
     	this.activeIndex=this.$route.query.id
  	    this.getIndex(this.activeIndex)
  	    document.documentElement.scrollTop=3000
     }
  }
};
</script>


<style type="text/css" scoped lang="less">
.introduction{
width:1200px;
margin:0 auto;
margin-top:15px;
.topicon{
   width:100%;
}
.intr_nav{
	overflow: hidden;
	padding:10px;
	padding-bottom:10px;
	margin-top:10px;
	background: #fff;
	border-bottom:2px solid #f1f1f1;
}
.intr_nav li{
	float: left;
	margin:0 30px;
	padding-bottom:3px;
	cursor: pointer;
	transition: 0.3s all;
}
.li_active{
	border-bottom:3px solid  #0e82cb;
}
.tit_active{
	color:#0e82cb;
}
.intr_nav li img{
	width:35px;
	padding-right:5px;
}
.intr_nav li span{
	font-size:15px;
	position:relative;
	top:5px;
}




}
</style>